<template>
  <div class="course-list-page">
    <!-- DONE:面包屑导航 -->
    <el-breadcrumb separator="/" class="bread-crumb">
      <el-breadcrumb-item>
        <span @click="$router.push('/')">首页</span>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <span style="color: #333">{{
          typeName && !$route.query.type
            ? typeName
            : !typeName && $route.query.type
            ? "推荐课程"
            : "课程分类"
        }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="list-box" v-if="loading">
      <Course t="c" :list="courseList" />
      <el-pagination
        layout="prev,pager,next"
        :total="totalNumber"
        :page-size="pageSize"
        background
        prev-text="上一页"
        next-text="下一页"
        @current-change="pageChange"
      >
      </el-pagination>
      <el-empty
        v-if="empty"
        :image="
          require(`../assets/images/${
            $route.query.tid ? 'no_course' : 'no_paper'
          }.png`)
        "
        :description="`暂无课程`"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import Course from "../components/Course.vue";

export default {
  data() {
    return {
      typeName: "",
      totalNumber: 1,
      pageSize: 1,
      courseList: [],
      currentPage: 1, //默认
      getCourseListShow: false, //有id的课程列表
      getCourseAllShow: false, //全部课程列表
      empty: false,
      loading: false,
      pageEmpty: false,
      recommendPage: false,
    };
  },

  components: { Course },

  computed: {},
  mounted() {
    if (this.$route.query.tid) {
      this.getCourseList();
      this.getCourseListShow = true; //有id的课程列表
    }
  },
  methods: {
    //获取有id的列表
    getCourseList(id, page = 1) {
      this.typeName = this.$route.query.tn;
      let fd = new FormData();
      fd.append("type_id", this.$route.query.tid);

      this.axios
        .get(
          `/coursepc/index/course_list?type_id=${this.$route.query.tid}&page=${page}`
        )
        .then((res) => {
          this.totalNumber = res.data.count;
          this.pageSize = Number(res.data.page_size);
          this.courseList = res.data.course_list;
          this.loading = true;
          if (this.totalNumber == 0) {
            this.empty = true;
          } else {
            this.pageEmpty = true;
          }
        });
    },

    pageChange(page) {
      this.currentPage = page;
      this.getCourseList(this.$route.query.tid, page);
    },
  },
  watch: {
    "$route.query.tid"(val) {
      this.getCourseList(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.course-list-page {
  min-height: calc(100vh - 80px);
  background-color: #fafafa;
  overflow: hidden;
  .list-box {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: left;
    box-sizing: border-box;
    .list-item {
      display: inline-block;
      width: calc(1160px / 5);
      margin-bottom: 40px;
      .list-item-content {
        cursor: pointer;
        width: 200px;
        height: 130px;
        margin: 0 auto;
        .content-img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          margin-bottom: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .content-text {
          color: #333333;
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.course-list-page {
  .list-box {
    .el-pagination {
      text-align: center;
      margin-top: 30px;
      .btn-prev,
      .btn-next {
        background-color: #fff !important;
        border: 1px solid #66a4ff;
        border-radius: 3px !important;
        padding: 5px 10px !important;
        box-sizing: border-box !important;
        &:hover {
          color: #66a4ff;
        }
        span {
          line-height: 1;
        }
      }
      .el-pager {
        li {
          font-weight: normal;
        }
      }
    }
  }
}
</style>
