<template>
  <div class="course-wrapper">
    <div
      v-for="li in list"
      :key="li.id"
      class="course-item"
      @click="jumpTo(li)"
    >
      <div>
        <div class="img-wrapper">
          <img :src="li.cover_img" alt="img" v-show="t === 'm' || t === 'c'" />
          <img :src="li.pic" alt="img" v-show="t === 'l'" />
        </div>
        <div class="desc">
          <div class="title">
            <span :title="li.title" v-show="t === 'c' || t === 'l'">{{
              li.title
            }}</span>
            <span :title="li.video_name" v-show="t === 'm'">{{
              li.video_name
            }}</span>
          </div>
          <div class="info-course" v-show="t === 'c'">
            {{ li.video_num }}节 · 已有{{ li.buy_num }}人购买
          </div>
          <div class="info-live" v-show="t === 'l'">
            <p>时间：{{ li.live_time }}</p>
            <p>讲师：{{ li.teacher }}</p>
          </div>
          <div class="info-live" v-show="t === 'm'">
            <p>讲师：{{ li.teacher }}</p>
          </div>
          <div class="price">
            <b v-show="t === 'c'">{{ "￥" + li.price }}</b>
            <b v-show="t === 'm'">免费</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "course",
  props: {
    t: {
      type: String,
      default: "c",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },

  components: {},

  computed: {},

  methods: {
    jumpTo(li) {
      if (this.t === "l") {
        window.open(li.url, "_blank");
        return;
      }
      if (this.t === "c") {
        this.$router.push(
          `/courdetails?cid=${li.id}&ct=${li.title}&tid=${
            this.$route.query.tid ? this.$route.query.tid : li.type_id
          }`
        );
      }
      if (this.t === "m") {
        this.$router.push(`/courplay?fid=${li.id}`);
      }
    },
    toVideo(vid) {
      this.$router.push(
        `/courplay?vid=${vid}&cid=${this.basicInfo.id}&ct=${this.basicInfo.type_name}`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.course-wrapper {
  padding: 15px 0;
}
.course-item {
  cursor: pointer;
  display: inline-block;
  width: calc(1160px / 4);
  min-height: 275px;
  border-radius: 5px;
  background-color: #fff;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
  margin: 5px;
  &:hover {
    box-shadow: 2px 6px 8px rgba(0, 0, 0, 0.16);
  }

  .img-wrapper {
    width: 100%;
    height: 172px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .desc {
    font-size: 14px;
    padding: 15px;
    box-sizing: border-box;
    .title {
      color: #333333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
    }
    .info-course,
    .info-live {
      color: #9199a1;
      font-size: 12px;
      margin: 10px 0;
      p {
        margin: 0;
      }
    }
    .price {
      color: #f01414;
    }
  }
}
</style>
